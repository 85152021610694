// Foundation
Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a>Zurück</a></li>';
Foundation.Drilldown.defaults.closeOnClick = true;
$(document).foundation();

// Plyr
(function(d, p){
    var a = new XMLHttpRequest(),
        b = d.body;
    a.open('GET', p, true);
    a.send();
    a.onload = function() {
        var c = d.createElement('div');
        c.setAttribute('hidden', '');
        c.innerHTML = a.responseText;
        b.insertBefore(c, b.childNodes[0]);
    };
})(document, '/assets/sprite.svg');
plyr.setup();

$(document).ready(function() {

    $('*[data-href]').on('click', function() {
        var url = $(this).attr('data-href');
        if(url == '/speiseplan') {
            window.location.href = url;
            return;
        }
        win = window.open(url, '_blank');
    });

    var zoom_level = 100;
    $('#zoomOut').on('click', function() {
        zoom_page(-10, $(this));
    });
    $('#zoomIn').on('click', function() {
        zoom_page(10, $(this));
    });

    // Zoom function
    function zoom_page(step, trigger)
    {
        // Zoom just to steps in or out
        if(zoom_level>=120 && step>0 || zoom_level<=80 && step<0) return;

        // Set / reset zoom
        if(step==0) zoom_level=100;
        else zoom_level=zoom_level+step;

        // Set page zoom via CSS
        $('body').css({
            transform: 'scale('+(zoom_level/100)+')', // set zoom
            transformOrigin: '50% 0' // set transform scale base
        });

        // Adjust page to zoom width
        //if(zoom_level>100) $('body').css({ width: (zoom_level*1.2)+'%' });
        //else $('body').css({ width: '100%' });

        // Activate / deaktivate trigger (use CSS to make them look different)
        //if(zoom_level>=120 || zoom_level<=80) trigger.addClass('disabled');
        //else trigger.parents('ul').find('.disabled').removeClass('disabled');
        //if(zoom_level!=100) $('#zoom_reset').removeClass('disabled');
        //else $('#zoom_reset').addClass('disabled');
    }

});